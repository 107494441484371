import { useEffect, useState } from 'react';

export function useInterval({
  startImmediate,
  duration,
  callback,
}: {
  startImmediate: boolean;
  duration: number;
  callback: () => void;
}) {
  const [count, updateCount] = useState(0);
  const [intervalState, setIntervalState] = useState(startImmediate === undefined ? true : startImmediate);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(
    () => {
      if (intervalState) {
        const ivId = setInterval(() => {
          updateCount(count + 1);
          callback && callback();
        }, duration);
        setIntervalId(ivId as any);
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId!);
          setIntervalId(null);
        }
      };
    },
    [intervalState, count, duration, callback, intervalId]
  );
  return {
    intervalId,
    start: () => {
      setIntervalState(true);
    },
    stop: () => {
      setIntervalState(false);
    },
  };
}
